import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutThisStore: {
    defaultMessage: 'About this store',
    id: 'storePage.aboutThisStore'
  },
  allCountries: {
    defaultMessage: 'All Countries',
    id: 'storePages.allCountries'
  },
  backToStoresOverview: {
    defaultMessage: 'Back to stores overview',
    id: 'storePages.backToStoresOverview'
  },
  bestsellersBody: {
    defaultMessage: 'Some text here about check our collection online',
    id: 'storePage.bestsellersBody'
  },
  bestsellersChunk: {
    defaultMessage: 'bestsellers',
    id: 'storePage.bestsellersChunk'
  },
  bookCTA: {
    defaultMessage: 'Book',
    id: 'storePage.bookCTA'
  },
  bookEyeTest: {
    defaultMessage: 'Book eye test',
    id: 'storePage.bookEyeTest'
  },
  bookFreeEyeTest: {
    defaultMessage: 'Book free eye test',
    id: 'storePage.bookFreeEyeTest'
  },
  bookPaidEyeTest: {
    defaultMessage: 'Book eye test',
    id: 'storePage.bookEyeTest'
  },
  discoverInstoreServices: {
    defaultMessage: 'Discover our in-store services',
    id: 'storePage.discoverInstoreServices'
  },
  experiencedOpticians: {
    defaultMessage: `Experienced opticians in {neighbourhood}, {city} available daily during opening hours`,
    id: 'storePage.experiencedOpticians'
  },
  exploreOurBestsellers: {
    defaultMessage: 'Explore our {bestsellersChunk}',
    id: 'storePage.exploreOurBestsellers'
  },
  exteriorStoreImageAltDescription: {
    defaultMessage: 'Showing exterior of Ace & Tate {street} store in, {country}, {city}, {neighborhood}',
    id: 'storePage.exteriorStoreImageAltDescription'
  },
  faqTitle: {
    defaultMessage: 'Frequently asked {faqTitleQuestionsChunk}',
    id: 'storePage.faqTitle'
  },
  faqTitleQuestionsChunk: {
    defaultMessage: 'questions',
    id: 'storePage.faqTitleQuestionsChunk'
  },
  has_free_eye_test_cta: {
    defaultMessage: 'Book free eye test',
    id: 'storePage.service.cta.freeEyeTest'
  },
  has_paid_eye_test_cta: {
    defaultMessage: 'Book eye test',
    id: 'storePage.service.cta.paidEyeTest'
  },
  inStoreServices: {
    defaultMessage: 'In store {services}',
    id: 'storePage.inStoreServices'
  },
  infoCardBody: {
    defaultMessage:
      'Did you know we offer free eye tests in most of our stores? Choose your favorite one and book away.',
    id: 'storePage.infoCardBody'
  },
  infoCardBodyGB: {
    defaultMessage: 'Did you know we offer eye tests in most of our stores? Choose your favorite one and book away.',
    id: 'storePage.infoCardBodyGB'
  },
  interiorStoreImageAltDescription: {
    defaultMessage: 'Showing interior of Ace & Tate {street} store in, {country}, {city}, {neighborhood}',
    id: 'storePage.interiorStoreImageAltDescription'
  },
  learn_more_cta: {
    defaultMessage: 'Learn more',
    id: 'storePage.service.cta.learnMore'
  },
  locationChunk: {
    defaultMessage: 'in {location}',
    id: 'storePage.sidebar.locationChunk'
  },
  locationInformation: {
    defaultMessage: 'Location information',
    id: 'storePage.locationInformation'
  },
  mapAltDescription: {
    defaultMessage: 'Map showing the location of Ace & Tate {street} store in {country}, {city}, {neighborhood}',
    id: 'storePage.mapAltDescription'
  },
  mapUseMyLocation: {
    defaultMessage: 'Use my location',
    id: 'storePages.mapUseMyLocation'
  },
  nearbyStores: {
    defaultMessage: 'Nearby {stores}',
    id: 'storePages.nearbyStores'
  },
  openInGoogleMaps: {
    defaultMessage: 'Open in Google Maps',
    id: 'storePage.openInGoogleMaps'
  },
  openingHoursTitle: {
    defaultMessage: 'Opening hours',
    id: 'storePage.openingHoursTitle'
  },
  orSearchOnTheMap: {
    defaultMessage: 'Or search on the map',
    id: 'storePage.sidebar.searchLabel'
  },
  qualityGlassesSunglasses: {
    defaultMessage: 'Quality glasses and sunglasses available in {neighbourhood}, {city} during opening hours.',
    id: 'storePage.qualityGlassesSunglasses'
  },
  searchQueryNotFound: {
    defaultMessage: `Sorry, we couldn't find "{searchQuery}".`,
    id: 'storePage.searchQueryNotFound'
  },
  searchQueryNotFoundSuggestions: {
    defaultMessage: `Please modify your search and try again or {directoryLink}.`,
    id: 'storePage.searchQueryNotFoundSuggestions'
  },
  searchQueryNotFoundSuggestionsDirectoryLink: {
    defaultMessage: 'browse our directory instead',
    id: 'storePage.searchQueryNotFoundSuggestionsLink'
  },
  searchStoreLabelHidden: {
    defaultMessage: 'Search store',
    id: 'storePages.searchStoreLabelHidden'
  },
  searchStorePlaceholder: {
    defaultMessage: 'Search by city',
    id: 'storePages.searchStorePlaceholder'
  },
  seeStoreDetails: {
    defaultMessage: 'See store details',
    id: 'storePage.seeStoreDetails'
  },
  seeStorePage: {
    defaultMessage: 'See store page',
    id: 'storePage.seeStorePage'
  },
  services: {
    defaultMessage: 'services',
    id: 'storePage.services'
  },
  sidebarTitle: {
    defaultMessage: 'Browse {aceandtate} stores',
    id: 'storePage.sidebar.title'
  },
  storePageTitle: {
    defaultMessage: 'Optician in {city} | Book a Free Eye Test',
    id: 'storePage.storePageTitle'
  },
  storesChunk: {
    defaultMessage: 'stores',
    id: 'storePages.storesChunk'
  },
  storesOverviewAllLocationsMetaTitle: {
    defaultMessage: 'All Ace & Tate Locations | Book a Free Eye Test',
    id: 'storePage.storesOverviewAllLocationsMetaTitle'
  },
  storesOverviewMetaTitle: {
    defaultMessage: 'Opticians in {location} | Book a Free Eye Test',
    id: 'storePage.storesOverviewMetaTitle'
  },
  switchToMapView: {
    defaultMessage: 'Or switch to map view',
    id: 'storePage.switchToMapView'
  }
});
