import { Flex, Typography, IconWorld, grid, brandColors } from '@aceandtate/ds';
import React, { HTMLAttributes } from 'react';
import ConditionalLink from 'components/ConditionalLink';
import styled from 'styled-components';

const StyledLink = styled(ConditionalLink)`
  &:hover {
    text-decoration: underline;
  }
`;

const BreadcrumbWrapper = styled(Flex)`
  flex-wrap: wrap;
  max-width: 100%;
  gap: ${grid[4]};
`;

const Crumb = styled(Typography)`
  white-space: nowrap;
`;

type BreadCrumbProps = HTMLAttributes<HTMLDivElement> & {
  sections: { url: string; text: string }[];
};

const Breadcrumb = ({ sections, ...props }: BreadCrumbProps) => {
  return (
    <BreadcrumbWrapper alignItems='center' {...props} itemScope itemType='https://schema.org/BreadcrumbList'>
      <IconWorld fontSize={24} color={brandColors.dark75} />
      {sections.map((section, i) => (
        <Crumb
          key={section.url}
          fontWeight='bold'
          forwardedAs='span'
          itemProp='itemListElement'
          itemScope
          itemType='https://schema.org/ListItem'
        >
          <StyledLink href={section.url} isLink={i < sections.length - 1} itemProp='item'>
            <span itemProp='name'>{section.text}</span>
          </StyledLink>
          <meta itemProp='position' content={`${i + 1}`} />
          &nbsp;
          {i !== sections.length - 1 && `/ `}
        </Crumb>
      ))}
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
